<template>
  <div>
    <el-table :data="dataList" border style="width: 100%; border-radius: 10px" :header-cell-style="{ background: '#285df0'}" :empty-text="emptyText">
      <el-table-column prop="time" label="更新时间" label-class-name="tablehead" align="center">
        <!-- <template #default="scope">
          <span>{{scope.row.time.substring(0,16)}}</span>
        </template> -->
      </el-table-column>
      <el-table-column  prop="state" label="设备状态" label-class-name="tablehead" align="center" >
          <template #default="scope">
            <span v-if="scope.row.state == 0" style="color: red;">离线</span>
            <span v-if="scope.row.state == 1" style="color: green;">正常</span>
          </template>
      </el-table-column>
      <el-table-column prop="humanPresence" label="人体存在" label-class-name="tablehead" align="center">
        <template #default="scope">
            <span v-if="scope.row.humanPresence == 0" style="color: red;">无人</span>
            <span v-if="scope.row.humanPresence == 1" style="color: green;">有人</span>
          </template>
      </el-table-column>
  
      <el-table-column prop="amplitude" label="体动幅度" label-class-name="tablehead" align="center">
        <template #default="scope">
            <span v-if="scope.row.amplitude == 0">--</span>
            <span v-if="scope.row.amplitude == 1">静止</span>
            <span v-if="scope.row.amplitude == 2">轻微</span>
            <span v-if="scope.row.amplitude == 3">小幅</span>
            <span v-if="scope.row.amplitude == 4">大幅</span>
          </template>
      </el-table-column>
      <el-table-column v-if="this.deviceData.deviceSubId == 1"  prop="inBed" label="在床状态" label-class-name="tablehead" align="center">
        <template #default="scope">
            <span v-if="scope.row.inBed == 0" style="color: red;">离床</span>
            <span v-if="scope.row.inBed == 1" style="color: green;">在床</span>
          </template>
      </el-table-column>
      <el-table-column v-if="this.deviceData.deviceSubId == 1" prop="heart" label="心率" label-class-name="tablehead" align="center">
        <template #default="scope">
          <span v-if="scope.row.humanPresence == 0">--</span>
          <span v-if="scope.row.humanPresence != 0">{{ scope.row.heart }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="this.deviceData.deviceSubId == 1" prop="respiratoryRate" label="呼吸率" label-class-name="tablehead" align="center">
        <template #default="scope">
          <span v-if="scope.row.humanPresence == 0">--</span>
          <span v-if="scope.row.humanPresence != 0">{{ scope.row.respiratoryRate }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="this.deviceData.deviceSubId == 2" prop="fall" label="跌倒报警" label-class-name="tablehead" align="center">
        <template #default="scope">
            <span v-if="scope.row.fall == 0">无预警</span>
            <span v-if="scope.row.fall == 1">预警</span>
          </template>
      </el-table-column>
      <el-table-column v-if="this.deviceData.deviceSubId == 2" prop="residency" label="驻留预警" label-class-name="tablehead" align="center">
        <template #default="scope">
            <span v-if="scope.row.residency == 0">无预警</span>
            <span v-if="scope.row.residency == 1">预警</span>
          </template>
      </el-table-column>
    </el-table>

  </div>
  <!-- private String deviceId;				//设备id
	private Integer humanPresence;	//有人无人  0-无人 1-有人
	private Integer inBed;					//在床离床  0-离床 1-在床
	private Integer amplitude;			//体动幅度	0-无人 1-静止 2-轻微 3-小幅 4-大幅 
	private Integer heart;					//心率
	private Integer respiratoryRate;//呼吸率	
	private Integer state;					//设备状态  0-离线 1-正常
	private Integer fall;						//跌倒报警	0-无预警 1-预警
	private Integer residency;			//驻留预警  0-无预警 1-预警 -->

</template>

<script>
import {
  getRadarStateInfo
} from '../../../api/api'
import session from "../../../store/store";
export default {
  data() {
    return {
      emptyText:'加载中',
      dataList: [],
      deviceData:{},
      timer:null,
      refresh:10,
    };
  },
  unmounted() {
    this.closeInterval();
  },
  methods: {
    getParentData(deviceData) {
      this.deviceData = deviceData;
      this.getRadarStateInfo();

      this.setInterval()
    },
    getRadarStateInfo() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        deviceId:this.deviceData.deviceId
      }
      getRadarStateInfo(param).then(res => {
        this.dataList.splice(0, this.dataList.length);
        if (res.data.code == 200) {
          var data = res.data.dataInfo;

          if(this.refresh != data.refresh) {
            this.refresh = data.refresh
            this.setInterval()
          }
          this.dataList.push(data);
        }else {
          this.emptyText = "暂无数据"
        }
      })
    },

    setInterval() {
      this.closeInterval();
      this.timer = setInterval(() => {
          this.getRadarStateInfo()
      }, this.refresh*1000);
    },

    closeInterval() {
      clearInterval(this.timer); //清除计时器
      this.timer = null; //设置为null
    },

  },
};
</script>

<style scoped>
.pagination {
  background-color: white;
  text-align: center;
  padding: 20px 0px;
  margin-top: 20px;
  border-radius: 10px;
}
</style>