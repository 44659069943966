<template>
  <div class="page">
    <ls-title-header @back="cancel" title="设备详情"></ls-title-header>
    <div class="box">
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="设备信息" name="first">
          <RadarBaseInfo ref="baseView" @cancel="cancel"></RadarBaseInfo>
        </el-tab-pane>
        <el-tab-pane label="设备状态" name="four">
          <RadarState ref="stateView" @cancel="cancel"></RadarState>
        </el-tab-pane>
        <el-tab-pane label="功能设置" name="second">
          <RadarSet ref="setView" @cancel="cancel"></RadarSet>
        </el-tab-pane>
        <el-tab-pane label="使用记录" name="third">
          <RadarRecord ref="recordView" @cancel="cancel"></RadarRecord>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import RadarBaseInfo from "./RadarBaseInfo.vue";
import RadarState from "./RadarState.vue";
import RadarSet from "./RadarSet.vue";
import RadarRecord from "./RadarRecord.vue";
export default {
  components: {
    RadarBaseInfo,
    RadarState,
    RadarSet,
    RadarRecord
  },
  data() {
    return {
      deviceData:null,
      activeName: "first",
    };
  },
  methods: {
    getParentData(data) {
      this.deviceData = data;
      setTimeout(() => {
        this.$refs.baseView.getParentData(data);
      }, 200);
    },
    cancel() {
      this.$emit("cancel");
    },
    handleClick(tab) {
      if(0 == tab.index) {
        setTimeout(() => {
          this.$refs.baseView.getParentData(this.deviceData);
        }, 200);
      }else if(1 == tab.index) {
        setTimeout(() => {
          this.$refs.stateView.getParentData(this.deviceData);
        }, 200);
      }else if(2 == tab.index) {
        setTimeout(() => {
          this.$refs.setView.getParentData(this.deviceData);
        }, 200);
      }else if(3 == tab.index) {
        setTimeout(() => {
          this.$refs.recordView.getParentData(this.deviceData);
        }, 200);
      }
    },
  },
};
</script>

<style scoped>
/* .page {
  padding: 40px 20px 40px 20px;
  background-color: white;
  border-radius: 4px;
} */
.page {
  padding: 0px 20px;
}
.box {
  /* width: 75%; */
  padding: 20px 20px;
  background-color: white;
  border-radius: 4px;
}
</style>
<style>
.el-tabs__item {
  font-size: 18px;
}
.el-tabs__nav-wrap::after {
  position: static !important;
}
</style>