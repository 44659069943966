<template>
  <div>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules"  label-width="160px" class="demo-ruleForm" >
      <div style="background-color: white">
        <div class="box">
          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="name">
                  <template #label><span class="title">绑定日期</span></template>
                  <span class="content">{{ruleForm.createTime}}</span>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row" v-if="this.role == 1">
            <el-col :span="24">
              <el-form-item>
                <template #label><span class="title">所属机构</span></template>
                <span class="content">{{ruleForm.unitName}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="name">
                  <template #label><span class="title">设备编号</span></template>
                  <span class="content">{{ruleForm.deviceId}}</span>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
                <el-form-item>
                  <template #label><span class="title">设备名称</span></template>
                  <span class="content">{{ruleForm.deviceName}}</span>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="24">
                <el-form-item>
                  <template #label><span class="title">设备类型</span></template>
                  <span class="content">{{ruleForm.deviceType}}</span>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="24">
                <el-form-item>
                  <template #label><span class="title">设备型号</span></template>
                  <span class="content">{{ruleForm.deviceModel}}</span>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="24">
                <el-form-item>
                  <template #label><span class="title">雷达传感器ID</span></template>
                  <span class="content">{{ruleForm.sensorId}}</span>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="24">
                <el-form-item>
                  <template #label><span class="title">通讯模组ID</span></template>
                  <span class="content">{{ruleForm.moduleId}}</span>
                  
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
                <el-form-item>
                  <template #label><span class="title">ICCID</span></template>
                  <span class="content">{{ruleForm.iccid}}</span>
                  
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
                <el-form-item>
                  <template #label><span class="title">IMSI</span></template>
                  <span class="content">{{ruleForm.imsi}}</span>
                 
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
                <el-form-item>
                  <template #label><span class="title">信息备注</span></template>
                  <el-input v-show="edit == true" v-model="ruleForm.remarks" placeholder="请输入信息备注" class="input"></el-input>
                  <span v-show="edit == false" class="content">{{ruleForm.remarks}}</span>
                </el-form-item>
            </el-col>
          </el-row>
          <hr />

          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="userName">
                  <template #label><span class="title">绑定用户</span></template>
                  <el-input  v-show="edit == true"  v-model="ruleForm.userName" placeholder="请选择用户"  class="input" disabled>
                    <template #append>
                      <el-button  @click="this.userVisible = true" type="primary"  style="color: white" >选择用户</el-button>
                    </template>
                  </el-input>

                  <span v-show="edit == false" class="content">{{ruleForm.userName}}</span>
                  <el-button v-show="edit == false"  @click="clearUser" type="primary" size="small" style="color: white;margin-left:10px" >清除</el-button>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="noticeName">
                  <template #label><span class="title">管&ensp;理&ensp;人</span></template>
                  <el-input  v-show="edit == true" v-model="ruleForm.noticeName"  placeholder="请选择管理人"  class="input" disabled>
                    <template #append>
                      <el-button @click="this.managerVisible = true" type="primary" style="color: white" >选择管理</el-button>
                    </template>
                  </el-input >
                  <span v-show="edit == false" class="content">{{ruleForm.noticeName}}</span>
                  <el-button v-show="edit == false"  @click="clearNotice" type="primary" size="small" style="color: white;margin-left:10px" >清除</el-button>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="positionName">
                  <template #label><span class="title">安装位置</span></template>
                  <el-input v-show="edit == true" v-model="ruleForm.positionName" placeholder="请选择安装位置" class="input" >
                    <template #append>
                      <el-button @click="this.posVisible = true" type="primary" style="color: white">安装位置</el-button>
                    </template>
                  </el-input>
                  <span v-show="edit == false" class="content">{{ruleForm.positionName}}</span>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="positionAddress">
                  <template #label><span class="title">安装地址</span></template>
                  <span v-show="edit == false" class="content">{{ ruleForm.positionAddress != "" ? ruleForm.positionAddress.replace(/#/g, "") : "--"}}</span>
                  <div v-show="edit != false">
                    <ls-address  style="display: flex;justify-content: space-between;width:100%;" @adr-change="getAddress" :province="this.address.province" :city="this.address.city" :district="this.address.district" :street="this.address.street"></ls-address>
                  </div>
                 
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row" v-show="edit != false">
            <el-col :span="24">
              <el-form-item>
                <el-input v-model="this.address.detail" placeholder="请输入详细地址" maxlength="50" show-word-limit class="input"></el-input>
              </el-form-item>
            </el-col>
          </el-row>


          <el-row class="row">
            <el-col :span="24">
              <el-form-item>
                <el-button v-show="(edit == false)&&(this.role != 1)" type="primary"  size="small" @click="edit = true" >编辑</el-button >
                <el-button v-show="edit == true" type="primary"  size="small"  @click="edit = false" plain >取消</el-button>
                <el-button type="primary"  size="small" v-show="edit == true" @click="submitForm('ruleForm')">提交</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>
  

    <template v-if="userVisible">
      <RadarAddUser @update="updateUser" @cancel="this.userVisible = false"></RadarAddUser>
    </template>
    <template v-if="managerVisible">
      <RadarAddManager @update="updateManager" @cancel="this.managerVisible = false"></RadarAddManager>
    </template>
    <template v-if="posVisible">
      <RadarAddPos  @update="updatePosData" @cancel="this.posVisible = false"></RadarAddPos>
    </template>
  </div>
</template>

<script>
import {
  getManagerInfo,
  getRadarInfo,
  editRadarInfo
} from '../../../api/api'
import session from "../../../store/store";
import util from "../../../until/until";
import RadarAddUser from './RadarAddUser.vue'
import RadarAddManager from './RadarAddManager.vue'
import RadarAddPos from './RadarAddPos.vue'
export default {
  components: {
    RadarAddUser,
    RadarAddManager,
    RadarAddPos
  },
  data() {
    return {
      edit: false,
      userVisible: false,
      managerVisible: false,
      posVisible: false,
      role: session.getLoginInfo().accountType,
      ruleForm: {
        userName: "",
        noticeName:"",
        positionName:"",
        positionAddress:'',
      },
      address: {
          province:'',
          city:'',
          district:'',
          street:'',
          detail:'',
      },
      rules: {},
      deviceData:{}
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
    },
    getParentData(data) {
      this.deviceData = data;
      this.getRadarInfo();
    },
    getRadarInfo() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        deviceId:this.deviceData.deviceId,
      }
      getRadarInfo(param).then(res => {
        if (res.data.code == 200) {
          this.ruleForm = res.data.dataInfo;

          let addressArr = this.ruleForm.positionAddress.split("#");
          if(addressArr.length == 5) {
            this.address.province = addressArr[0];
            this.address.city = addressArr[1];
            this.address.district = addressArr[2];
            this.address.street = addressArr[3];
            this.address.detail = addressArr[4];
          }
          this.getManagerInfo();
        }
      })
    },
    getManagerInfo() {
      var param = {
        uuid: this.ruleForm.noticeUuid,
      }
      getManagerInfo(param).then(res => {
        if (res.data.code == 200) {
          this.ruleForm.noticeName = res.data.dataInfo.name
        } 
      })
    },
    clearUser() {
      this.$confirm('确定清空绑定用户?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.ruleForm.unitUuid = session.getLoginInfo().unitUuid,
          this.ruleForm.idcard = '0000'
          editRadarInfo(this.ruleForm).then(res => {
            if (res.data.code == 200) {
              this.ruleForm.userName = ''
              this.$message({ message: '清空成功',  type: 'success'});
            } else {
              this.$message.error({ message: res.data.message});
            }
          })
        }).catch(() => {});
    },
    clearNotice() {
      this.$confirm('确定清空管理员?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.ruleForm.unitUuid = session.getLoginInfo().unitUuid,
          this.ruleForm.noticeUuid = '0000'
          editRadarInfo(this.ruleForm).then(res => {
            if (res.data.code == 200) {
              this.ruleForm.noticeName = ''
              this.$message({ message: '清空成功',  type: 'success'});
            } else {
              this.$message.error({ message: res.data.message});
            }
          })
        }).catch(() => {});
    },
    updateUser(data) {
      this.ruleForm.idcard = data.idcard;
      this.ruleForm.userName = data.name;
      this.ruleForm.positionName = data.name + '居住地'
      if(!util.isNULL(data.address)) {
        let addressArr = data.address.split("#");
        if(addressArr.length == 5) {
          this.address.province = addressArr[0];
          this.address.city = addressArr[1];
          this.address.district = addressArr[2];
          this.address.street = addressArr[3];
          this.address.detail = addressArr[4];
        }
      }
    },
    updateManager(data) {
      this.ruleForm.noticeUuid = data.uuid;
      this.ruleForm.noticeName = data.name;
    },
    updatePosData(data) {
      this.ruleForm.positionUuid = data.uuid;
      this.ruleForm.positionName = data.name;
      this.ruleForm.positionAddress = data.address;

      if(!util.isNULL(data.address)) {
        let addressArr = data.address.split("#");
        if(addressArr.length == 5) {
          this.address.province = addressArr[0];
          this.address.city = addressArr[1];
          this.address.district = addressArr[2];
          this.address.street = addressArr[3];
          this.address.detail = addressArr[4];
        }
      }
    },
    getAddress(data){
      this.address.province = data.selectProvince;
      this.address.city = data.selectCity;
      this.address.district =  data.selectDistrict;
      this.address.street = data.selectStreet
    },
    submitForm(ruleName) {
      this.$refs[ruleName].validate((valid) => {
        if (valid) {
         this.editRadarInfo();
        } else {
          return false;
        }
      });
    },
    editRadarInfo() {
      if (util.isNULL(this.address.detail)) {
        this.$message.error("请输入完整地址")
        return;
      }

      let address = this.address.province + '#' + this.address.city + '#' + this.address.district + '#' + this.address.street + '#' + this.address.detail;

      if(address == '####') {
        this.$message.error("请选择地址")
        return;
      }
      this.ruleForm.positionAddress = address
      this.ruleForm.unitUuid = session.getLoginInfo().unitUuid,
      editRadarInfo(this.ruleForm).then(res => {
        if (res.data.code == 200) {
          this.$message({ message: '修改成功',  type: 'success'});
          this.cancelEdit();
        } else {
          this.$message.error({ message: res.data.message});
        }
      })
    },
    cancelEdit() {
      this.edit = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  text-align: center;
  margin: 20px 0px;
}
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -19px;
}
.input {
  width: 100%;
}
hr {
  border-color: #f4f4f526;
}
.title {
  margin-right: 25px;
  font-size: 16px;
  color: #8D8D8D;
}
.box {
  width: 75%;
}
.content {
  font-size: 16px;
  margin-left: 10px;
}
.row {
  margin: 20px 0px 20px 0px;
}

</style>
