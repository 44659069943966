<template>
  <div>
    <el-table :data="dataList" border style="width: 100%; border-radius: 10px" :header-cell-style="{ background: '#285df0'}" :empty-text="emptyText">
      <el-table-column prop="createTime" label="操作时间" label-class-name="tablehead" align="center">
        <template #default="scope">
          <span>{{scope.row.createTime.substring(0,16)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="describe" label="信息内容" label-class-name="tablehead" align="center"/>
    </el-table>

    <div class="pagination">
      <ls-pagination :currentPage="currentPage" :page-size="size" :total="total" @size-change="handleSizeChange" @cur-change="handleCurrentChange"></ls-pagination>
    </div>
  </div>
 
</template>

<script>
import {
  getRadarUseInfos
} from '../../../api/api'
import session from "../../../store/store";
export default {
  data() {
    return {
      emptyText:'加载中',
      currentPage: 1,
      size: 10,
      total: 0, //总数
      dataList: [],
      deviceData:{}
    };
  },
  methods: {
    getParentData(deviceData) {
      this.deviceData = deviceData;
      this.getRadarUseInfos();
    },
    getRadarUseInfos() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        deviceId:this.deviceData.deviceId,
        page:this.currentPage - 1,
        size:this.size,
      }
      getRadarUseInfos(param).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.count;
          this.dataList = res.data.dataInfo;
        }else {
          this.total = 0;
          this.dataList.splice(0, this.dataList.length);
          this.emptyText = "暂无数据"
        }
      })
    },

      //分页
    handleSizeChange(val) {
      this.size = val;
      this.getRadarUseInfos();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getRadarUseInfos();
    },
  },
};
</script>

<style scoped>
.pagination {
  background-color: white;
  text-align: center;
  padding: 20px 0px;
  margin-top: 20px;
  border-radius: 10px;
}
</style>