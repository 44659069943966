<template>
  <div>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules"  label-width="160px" class="demo-ruleForm" >
      <div style="background-color: white">
        <div class="box">

          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="name">
                  <template #label><span class="title">预警总开关</span></template>
                  <el-switch v-if="edit == true" v-model="this.deviceData.switchOn" active-text="开" inactive-text="关"></el-switch>
                  <span v-show="edit == false" class="content" v-if="this.ruleForm.switchOn == 1">开启</span>
                  <span v-show="edit == false" class="content" v-if="this.ruleForm.switchOn == 0">关闭</span>
                </el-form-item>
            </el-col>
          </el-row>


            <hr />

          <div v-if="this.deviceData.deviceSubId == 1 || this.deviceData.deviceSubId == 3"> 
              <el-row class="row">
                <el-col :span="24">
                    <el-form-item prop="name">
                      <template #label><span class="title">离床监控</span></template>
                      <span v-show="edit == false" class="content">{{ this.deviceData.outBedBeginEndTime }}</span>
                      <el-time-picker v-if="edit == true"  v-model="this.deviceData.outBedTime" is-range range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间" format="HH:mm" value-format="HH:mm" style="width:90%" />
                    </el-form-item>
                </el-col>
              </el-row>

              <el-row class="row">
                <el-col :span="24">
                    <el-form-item prop="outBedDuration">
                      <template #label><span class="title">离床时长</span></template>
                      <el-input v-show="edit == true" v-model="this.deviceData.outBedDuration" type="number" placeholder="请输入离床时长" class="input">
                        <template #append>分钟</template>
                      </el-input>
                      <span v-show="edit == false" class="content">{{this.deviceData.outBedDuration}}分钟</span>
                    </el-form-item>
                </el-col>
              </el-row>

              <el-row class="row">
                <el-col :span="24">
                    <el-form-item prop="unmanned">
                      <template #label><span class="title">无人预警</span></template>
                      <el-input v-show="edit == true" v-model="this.deviceData.unmanned" type="number" placeholder="请输入无人预警时长" class="input">
                        <template #append>小时</template>
                      </el-input>
                      <span v-show="edit == false" class="content">{{this.deviceData.unmanned}}小时</span>
                    </el-form-item>
                </el-col>
              </el-row>
            </div>

            <div v-if="this.deviceData.deviceSubId == 1"> 
              <hr />

              <el-row class="row">
                <el-col :span="24">
                    <el-form-item prop="name">
                      <template #label><span class="title">心率</span></template>
                      <div class="input" style=" display: flex;align-items: center;justify-content: space-between;">
                        <el-input v-show="edit == true" v-model="this.deviceData.minHeart" type="number" placeholder="请输入最小心率" style="width:44%">
                        </el-input>
                        <span v-show="edit == true"> - </span>
                        <el-input v-show="edit == true" v-model="this.deviceData.maxHeart" type="number" placeholder="请输入最大心率" style="width:44%">
                        </el-input>
                      </div>
                      <span v-show="edit == false" class="content">{{this.deviceData.minHeart}}-{{this.deviceData.maxHeart}}</span>
                    </el-form-item>
                </el-col>
              </el-row>

              <el-row class="row">
                <el-col :span="24">
                    <el-form-item prop="name">
                      <template #label><span class="title">呼吸率</span></template>
                      <div class="input" style=" display: flex;align-items: center;justify-content: space-between;">
                        <el-input v-show="edit == true" v-model="this.deviceData.minRespiratoryRate" type="number" placeholder="请输入最小呼吸率" style="width:44%">
                        </el-input>
                        <span v-show="edit == true"> - </span>
                        <el-input v-show="edit == true" v-model="this.deviceData.maxRespiratoryRate" type="number" placeholder="请输入最大呼吸率" style="width:44%">
                        </el-input>
                      </div>
                      <span v-show="edit == false" class="content">{{this.deviceData.minRespiratoryRate}}-{{this.deviceData.maxRespiratoryRate}}</span>
                    </el-form-item>
                </el-col>
              </el-row>

           

              <el-row class="row">
                <el-col :span="24">
                    <el-form-item prop="name">
                      <template #label><span class="title">心率呼吸灵敏度</span></template>
                      <el-input v-show="edit == true" v-model="this.deviceData.hrSensitivity" type="number" placeholder="请输入开门时长" class="input">
                        <template #append>秒</template>
                      </el-input>
                      <span v-show="edit == false" class="content">{{this.deviceData.hrSensitivity}}秒</span>
                    </el-form-item>
                </el-col>
              </el-row>
            </div>

            <div v-if="this.deviceData.deviceSubId == 2"> 
              <el-row class="row">
                <el-col :span="24">
                    <el-form-item prop="name">
                      <template #label><span class="title">跌倒预警灵敏度</span></template>
                      <el-input v-show="edit == true" v-model="this.deviceData.fallSensitivity" type="number" placeholder="请输入开门时长" class="input">
                        <template #append>秒</template>
                      </el-input>
                      <span v-show="edit == false" class="content">{{this.deviceData.fallSensitivity}}秒</span>
                    </el-form-item>
                </el-col>
              </el-row>

              <el-row class="row">
                <el-col :span="24">
                    <el-form-item prop="name">
                      <template #label><span class="title">驻留预警灵敏度</span></template>
                      <el-input v-show="edit == true" v-model="this.deviceData.residencySensitivity" type="number" placeholder="请输入开门时长" class="input">
                        <template #append>秒</template>
                      </el-input>
                      <span v-show="edit == false" class="content">{{this.deviceData.residencySensitivity}}秒</span>
                    </el-form-item>
                </el-col>
              </el-row>

              <el-row class="row">
                <el-col :span="24">
                    <el-form-item prop="name">
                      <template #label><span class="title">安装高度</span></template>
                      <el-input v-show="edit == true" v-model="this.deviceData.installationHeight" type="number" placeholder="请输入开门时长" class="input">
                        <template #append>厘米</template>
                      </el-input>
                      <span v-show="edit == false" class="content">{{this.deviceData.installationHeight}}厘米</span>
                    </el-form-item>
                </el-col>
              </el-row>
            </div>
        </div>

          <el-row class="row">
            <el-col :span="24">
              <el-form-item>
                <el-button v-show="(edit == false)&&(this.role != 1)" type="primary" size="small" @click="edit = true" >编辑</el-button >
                <el-button v-show="edit == true" type="primary" size="small"  @click="edit = false" plain >取消</el-button>
                <el-button type="primary"  size="small" v-show="edit == true" @click="submitForm()">提交</el-button>
              </el-form-item>
            </el-col>
          </el-row>

      </div>
    </el-form>
  
  </div>
</template>

<script>
import {
  getRadarSetInfo,
  editRadarSetInfo
} from '../../../api/api'
import session from "../../../store/store";
export default {
  data() {
    return {
      edit: false,
      role: session.getLoginInfo().accountType,
      deviceData:{},
      ruleForm: {},
      rules: {
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
    },
    getParentData(deviceData) {
      this.deviceData = deviceData;
      console.log(this.deviceData)
      this.deviceData.outBedTime = []

 
      this.getRadarSetInfo();
    },
    getRadarSetInfo() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        deviceId:this.deviceData.deviceId,
        deviceSubId:this.deviceData.deviceSubId
      }
      getRadarSetInfo(param).then(res => {
        if (res.data.code == 200) {
          this.ruleForm = res.data.dataInfo
          this.toData(res.data.dataInfo)
        }else {
          this.$message.error({ message: res.data.message});
        }
      })
    },

    submitForm() {
      this.editRadarSetInfo()
    },

    editRadarSetInfo() {
      this.ruleForm.deviceId = this.deviceData.deviceId
      this.ruleForm.deviceSubId = this.deviceData.deviceSubId
      this.ruleForm.unitUuid = session.getLoginInfo().unitUuid
      this.toEditData()
      editRadarSetInfo(this.ruleForm).then(res => {
        if (res.data.code == 200) {
          this.$message.success({ message: "修改成功"});
          this.closeEdit();
          this.getRadarSetInfo()
        }else {
          this.$message.error({ message: res.data.message});
        }
      })
    },

    toEditData() {
      if(this.deviceData.switchOn == true) {
        this.ruleForm.switchOn = 1
      }else {
        this.ruleForm.switchOn = 0
      }
      this.ruleForm.outBedStartTime = this.deviceData.outBedTime[0]
      this.ruleForm.outBedEndTime = this.deviceData.outBedTime[1]
      this.ruleForm.outBedDuration = this.deviceData.outBedDuration
      this.ruleForm.unmanned = this.deviceData.unmanned
      this.ruleForm.minHeart = this.deviceData.minHeart

      this.ruleForm.maxHeart = this.deviceData.maxHeart
      this.ruleForm.minRespiratoryRate = this.deviceData.minRespiratoryRate
      this.ruleForm.maxRespiratoryRate = this.deviceData.maxRespiratoryRate
      this.ruleForm.hrSensitivity = this.deviceData.hrSensitivity
      this.ruleForm.fallSensitivity = this.deviceData.fallSensitivity
      this.ruleForm.residencySensitivity = this.deviceData.residencySensitivity
      this.ruleForm.installationHeight = this.deviceData.installationHeight
    },

    toData(data) {
        if(data.switchOn == 1) {
          this.deviceData.switchOn = true
        }else {
          this.deviceData.switchOn = false
        }

        this.deviceData.outBedTime[0] = data.outBedStartTime
        this.deviceData.outBedTime[1] = data.outBedEndTime
        
        this.deviceData.outBedBeginEndTime = data.outBedStartTime + "~" + data.outBedEndTime

        this.deviceData.outBedDuration = data.outBedDuration
        this.deviceData.unmanned = data.unmanned
        this.deviceData.minHeart = data.minHeart
        this.deviceData.maxHeart = data.maxHeart
        this.deviceData.minRespiratoryRate = data.minRespiratoryRate
        this.deviceData.maxRespiratoryRate = data.maxRespiratoryRate
     
        this.deviceData.hrSensitivity = data.hrSensitivity

        this.deviceData.fallSensitivity = data.fallSensitivity
        this.deviceData.residencySensitivity = data.residencySensitivity
        this.deviceData.installationHeight = data.installationHeight
    },
    closeEdit() {
      this.edit = false
    },

  },
};
</script>

<style lang="scss" scoped>
.pagination {
  text-align: center;
  margin: 20px 0px;
}
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -19px;
}
.input {
  width: 90%;
}
hr {
  border-color: #f4f4f526;
}
.title {
  margin-right: 10px;
  font-size: 16px;
  color: #8D8D8D;
}
.box {
  width: 75%;
}
.content {
  font-size: 16px;
  margin-left: 10px;
}
.row {
  margin: 20px 0px 20px 0px;
}

</style>
